@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    div.react-select input{
        @apply border-0 my-0 mx-0 focus:ring-0 focus:outline-none;
    }

    input.omnisight, button, select, textarea {
        @apply rounded focus:outline-none focus:ring-2 focus:ring-link-left-nav-hover border border-separators-page shadow-sm p-1 px-2 m-1 leading-normal bg-input-back;
    }
    input.omnisight, button, select {
        @apply h-9;
    }
    input:disabled {
        background: #EFEFEF;
        color: #999999 !important;
    }
    .page-container {
        @apply bg-background-page border border-separators-page w-full p-5;
    }
    
}
@layer base {
    h1 {
        @apply text-2xl;
        @apply font-bold;
    }
    h2 {
        @apply text-xl;
        @apply font-semibold;
    }
}