:root {
  /* Background Colors */
  --body-background-color: #2E2E2E;
  --page-background-color: white;
  --left-nav-background-color: #2E2E2E;
  --top-nav-background-color: #F5F5F5;
  --top-nav-tabs-background-color: #FBFBFB;
  --container-title-background-color: #F8F8F8;
  --dialog-title-background-color: #323F55;
  --dialog-footer-background-color: #EEEEEE;
  --alert-background-color: #F8D7DA;
  --page-background-highlight-gray: #F5F5F5;

  /* Input control colors */ 
  --toggle-background-color: #AAAAAA;

  /* Separator Colors */
  --page-border-color: #DEDEDE;
  --alert-border-color: #8B3F46;

  /* Top Nav Colors */
  --top-nav-logo-tint: #F3C327;
  --top-nav-tsis-logo-tint: black;

  /* Nav Link Colors */
  --left-nav-link-color: #FFFFFF;
  --left-nav-link-hover-color: #E89475;
  --left-nav-link-active-color: #C97A5D;
  --top-nav-link-color: #404040;
  --top-nav-link-hover-color: #48A8FF;
  --text-link-page-color: #C97A5D;

  /* Brand colors */
  --brand-primary: #C97A5D;
  --brand-primary-lightened: #E89475;
  --brand-primary-darkened: #AB6850;
  
  --brand-secondary: #3C4A60;
  --brand-secondary-lightened: #4A5972;
  --brand-secondary-darkened: #323F55;

  --brand-destructive: #bd3232;
  --brand-destructive-lightened: #343f3f;
  --brand-destructive-darkened: #ac2424;

  /* Text Colors */
  --text-body-muted: #AAAAAA;
  --text-body-alert: #b6b6b6;
  --text-bright: #FFFFFF;
  --text-semi-bright: #999999;
  --text-body: #616161;

  --input-background: white;
  
  /* Base Font Collection */
  --site-font-stack: sans;

  /* Toast colors */
  --warning-icon: orange;
  --error-icon: red;
  --success-icon: green;
  --info-icon: blue;
}

@media(prefers-color-scheme: dark_disabled) {
  :root {

  --body-background-color: #1e1e1e;
  --page-background-color: #363637;
  --left-nav-background-color: #363636;
  --top-nav-background-color: #363636;
  --alert-background-color: #F8D7DA;
  --page-background-highlight-gray: #F5F5F5;

  /* Input control colors*/
  --toggle-background-color: #333333;

  /* Separator Colors*/
  --page-border-color: #888888;
  --alert-border-color: #8B3F46;

  /* Top Nav Colors */
  --top-nav-logo-tint: #AAAAAA;
  --top-nav-tsis-logo-tint: #AAAAAA;

  /* Nav Link Colors */
  --left-nav-link-color: #AAAAAA;
  --left-nav-link-hover-color: #68C8FF;
  --left-nav-link-active-color: #48A8FF;
  --top-nav-link-color: #404040;
  --top-nav-link-hover-color: #48A8FF;

  /* Brand colors */
  --brand-primary: #f4c23f;
  --brand-primary-lightened: #FFDC6B;
  --brand-primary-darkened: #CDA115;
  
  --brand-secondary: #2a83d4;
  --brand-secondary-lightened: #48A8FF;
  --brand-secondary-darkened: #0D72D1;

  --brand-destructive: #bd3232;
  --brand-destructive-lightened: #343f3f;
  --brand-destructive-darkened: #ac2424;

  /* Text Colors */
  --text-body-muted: #AAAAAA;
  --text-body-alert: #FF1111;
  --text-bright: #FFFFFF;
  --text-body: #EFEFEF;

  --input-background: #686868;

  }
}

 