
@import 'variables.css';
@import './components';
@import './forms.css';
@import "../../../node_modules/keyboard-css/dist/css/main.min.css";

body {
  font-family: Quicksand, 'Open Sans', Helvetica, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: theme_color('header-text');
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}